@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
	.clip-bottom {
		clip-path: polygon(50% 100%, 100% 0, 0 0);
	}
}

@layer base {
	body {
		@apply font-roboto-mono;
	}

	p {
		@apply font-poppins;
	}

	header {
		padding: 10px;
	}

	aside {
		padding: 10px;
	}

	@media (min-width: 640px) {
		header,
		aside {
			padding-left: 18px;
			padding-right: 18px;
			padding-top: 30px;
		}

		header {
			padding-top: 42px;
		}
	}

	@media (min-width: 1024px) {
		header,
		aside {
			padding-left: 60px;
			padding-right: 60px;
			padding-top: 40px;
		}

		header {
			padding-top: 80px;
		}
	}

	/* For landing page on devices with landscape default view e.g Nest Hub */
	@media (min-width: 1024px) and (max-height: 600px) {
		.landing-hero {
			width: 90% !important;
			margin-top: auto !important;
			margin-left: auto !important;
		}

		.landing-container {
			display: flex !important;
		}
	}

	@media (min-width: 1024px) and (min-height: 600px) and (max-height: 800px) {
		.landing-hero {
			padding: 2.5rem !important;
			margin-top: auto !important;
			margin-bottom: auto !important;
		}

		.ocp-landing-animated-logo {
			width: 3rem;
		}

		.ocp-landing-intro-header {
			margin-top: 1rem !important;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
			transform: translateY(20px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
	.fade-in {
		animation: fadeIn 0.5s ease-in-out;
	}

	.lower-alpha {
		list-style-type: lower-alpha;
	}

	.small-bullets {
		list-style-type: disc;
	}

	.small-bullets li::marker {
		font-size: 0.8em;
	}

	.slick-dots.slick-thumb {
		height: 60px;
		position: relative !important;
	}

	.slick-dots.slick-thumb li {
		width: 90px !important;
		height: 60px !important;
		margin: 5px !important;
	}

	.slick-dots li.slick-active img {
		border: 2px solid #6e9af1;
	}

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	.blockchain-icon {
		filter: brightness(1);
	}

	.group:hover .blockchain-icon {
		filter: brightness(0) invert(1);
	}

	.terms-of-use-container .tou-highlight-span {
		font-weight: 700;
		text-decoration: underline;
	}

	/*.scrollbar-hide::-webkit-scrollbar {*/
	/*	display: none;*/
	/*}*/
	/*.scrollbar-hide {*/
	/*	scrollbar-width: none;*/
	/*}*/
	/*.scrollbar-hide {*/
	/*	-ms-overflow-style: none;*/
	/*}*/
	/*The following are the css rules needed for t&c page ordered list counter manipulation */
	/*For contexts and explanations -the following PR description*/
	/*https://github.com/oneshot-earth/OneShot-v2/pull/473#issue-2605891315*/
	.numbered-list {
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;
	}

	.numbered-list > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.6em;
	}

	.numbered-list > li:before {
		content: counters(item, ".") ". ";
		display: table-cell;
		padding-right: 0.6em;
	}

	.numbered-list li > ol {
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;
		margin-left: -29px;
	}

	.section-point-over-nine li {
		margin-left: -9px;
	}

	.numbered-list li > ol > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.6em;
	}

	.numbered-list li > ol > li:before {
		content: counters(item, ".") " ";
		display: table-cell;
		padding-right: 0.6em;
	}

	.numbered-list li > ol > li > ol {
		margin-left: -39px;
	}

	/*end of the css rules needed for t&c*/
	.notification-list-container {
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-y: scroll;
	}

	.notification-list-container::-webkit-scrollbar {
		display: none;
	}
}
